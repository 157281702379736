
.owl-carousel {
        position: relative;

        .owl-nav {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            .owl-next, .owl-prev {
                position: absolute;
                top: 50%;
                left: -15px;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);

                i {
                    font-size: 120%;
                }
            }

            .owl-next {
                right: -15px;
                left: auto;
            }
        }

        .owl-dots {
            text-align: center;
            margin-top: 40px;

            .owl-dot {
                display: inline-block;

                span {
                    width: 10px;
                    height: 10px;
                    margin: 5px;
                    background: #d6d6d6;
                    display: block;
                    -webkit-backface-visibility: visible;
                    transition: opacity .2s ease;
                    border-radius: 30px;
                }

                &.active {
                    span {
                        background: #869791;
                    }
                }
            }
        }
    }
