#login {


	#logo {
		margin-bottom: 40px;
	}
}

#login-form, [id^="login-form-"] {

	.form-group {

		.form-control {
			height: $formControlHeight;
		}

		&.icon {
			position: relative;

			.form-control {
				padding-left: 3rem;
			}

			i {
				color: $gray-400;
				position: absolute;
				height: $formControlHeight;
				padding: 0px 1rem;
				line-height: $formControlHeight;
			}
		}
	}

	
	a:not(.signin-button) {
		color: $gray-600;
	}

	.recover {
		margin-top: 10px;
		font-size: 12px;
	}

	.register-panel{
		padding-top: 20px;
	}

	.register-panel, .login-panel{
		h3{
			margin:0px;
			padding: 20px 0px 8px 0px;
		}
	}


}





.module_mod_iwp_users {
	
	hr {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	form{
		padding:16px 8px;
	}

	#login-greeting {
		margin-bottom: 1rem;

		p{
			position: relative;
			line-height: 22px;

			&.has-icon{
				padding-left:35px;
			}
			i{
				font-size: 24px;
				position: absolute;
				left: 0px;
			}
		}

	}

	.logout-button {
		margin-top: 1.5rem;

		button {
			color: $gray-600;
			text-transform: uppercase;
		}
	}

	.links {
		margin-top: 24px;

		li {
			border-top: 1px $gray-100 solid;

			a {
				padding: 10px 0px;
				font-size: 14px;
				display: block;
				text-transform: uppercase;
			}

			&:last-child {
				border-bottom: 1px $gray-100 solid;
			}
		}
	}

	&.pos_icons {

		@include media-breakpoint-up(lg) {

			.icon {
				color: $mainColor !important;
			}
		}

	}

}