.com_iwp_awards, .module_mod_iwp_products, .module_mod_iwp_filters {

	#content {
		background-color: $gray-100;
		//border-bottom: 1px $gray-200 solid;

		.module_mod_iwp_filters
        {
			.module-content {
				background-color: white;
			}
		}
	}

	#products, #product, #search-products {

		.item {
          position: relative;

          .inner {
            padding: 24px;
            border-radius: $border-radius;
            border: 1px solid $border-color;
            height: 100%;
          }


			.title {

				min-height: 50px;
				padding: 8px 0px;
				min-height: 120px;

				a, a:hover {
					font-size: 21px;
					color: inherit;
					color: $mainColor;
					text-decoration: none;
					display: block;
					padding: 6px 0px 10px 0px;

				}

				.brand {
					font-size: 16px;
				}
			}

			.price {

				position: absolute;
				border-radius: $border-radius;
				box-shadow: $box-shadow;
				right:-5px;
				top:-5px;
				background-color: white;
				text-align: center;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: center;

				> div{
					height: 40px;
					line-height: 28px;
					padding: 6px 10px;
					width: 100%;
				}

				.points{

					background-color: white;
					color: $primary;
					font-size: 115%;
					font-weight: bold;

					del{
						font-weight: normal;
					}
				}

				.currency{
					background-color: $secondary;
					color: white;
				}
			}

			.buttons{
				display: flex;
				button, a{
					text-transform: uppercase;
				}
				> div{
					flex:1;
					padding: 0px 8px;
					&:first-child{
						padding-left: 0px;
					}
					&:last-child{
						padding-right: 0px;
					}
				}
			}

			&:hover {

				.buttons {
					//animation: fadeInUp 0.3s;
					//transform: translate3d(0, 0, 0);
					//opacity: 1;
				}
			}

			.description {
				font-size: 14px;
				line-height: 2em;
				overflow: hidden;
				transition: all .25s linear 0s;
				margin-bottom: 40px;
			}

			a.read-more{
				display: block;
				width:100%;
				padding: 5px 0px;
				cursor: pointer;
				position: relative;
				font-size: 14px;
				&.closed:before{
					content: '';
					display: block;
					width:100%;
					height: 40px;
					position: absolute;
					margin-top: -40px;
					background: rgb(255,255,255);
					background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				}
			}

		}


		.shop_products_list .item{

			padding: 24px 0px;
			border-bottom: 1px solid $border-color;

			&:last-child{
				border-bottom: none;
			}

			.inner{
				display: flex;
				align-items: center;
				padding: 0px !important;
				border: none;

				.price{
					right:0px;
					top:16px;
					flex-direction: row;
				}


				.image{
					margin: 0px !important;
					padding-right: 24px;
				}

				.content{

					flex: 1;

					.title{
						text-align: left !important;
						min-height: auto;
					}

					.buttons{
						> div{
							flex: none !important;
						}
					}
				}

			}

		}


	}

	#products, #search-products {
      &.bordered {
			//padding-left: $grid-gutter-width/2;
			//padding-right: $grid-gutter-width/2;
			//border: #eee solid;
			//border-width: 0 0 1px 1px;

			.item {

				.inner {
					border: 1px $card-border-color solid;
					padding: $grid-gutter-width/2;
					background-color: white;
				}

				.title {
					text-align: center;

					@media (min-width: $screen-md) and (max-width: $screen-lg) {
						min-height: 12ex;
					}
				}
			}
		}

	}

	#product {

		.item {
			padding: 48px;
			background-color: white;
			border: 1px $card-border-color solid;

			@include media-breakpoint-down(sm) {
				padding: $grid-gutter-width/2;
			}

			.gallery-photo {
				margin-bottom: 10px;
			}

			/*
			.price {
				font-size: 22px;
				border-top: 1px $gray-200 solid;
				padding: 1rem 0;
				margin-top: .5rem;
			}*/

			.quantity {
				margin-left: 2rem;

				input {
					font-size: 20px;
					max-width: 6rem;
				}

				@include media-breakpoint-down(sm) {
					order: -1;
					margin-bottom: 8px;
					margin-left: 0;
					margin-right: 2rem;
				}

			}
		}
	}

	.toolbar {
		margin-bottom: 32px;
		font-size: $h6-font-size;


		.category-title {
			font-weight: 600 !important;
			color: $gray-200;
		}

		.breadcrumb {
			margin-bottom: 0;
		}

		.sort {

			.dropdown-menu {
				font-size: 14px;
			}
		}
	}

	#cartModal {

		.modal-body {
			.left-block {
				border-right: 1px solid #dee2e6;


				@include media-breakpoint-down(md) {
					border-bottom: 1px solid #dee2e6;
					border-right: 0;

					img {
						height: 200px;
					}
				}


				> div {
					display: inline-block;
					position: relative;

					i {
						position: absolute;
						right: 10px;
						top: -16px;
						color: #00b85b;
					}
				}
			}
		}

		@media (max-width: 991.98px) {
			.modal-dialog {
				max-width: calc(100% - (.5rem * 2));
				margin: .5rem auto;

				&.modal-dialog-centered {
					min-height: calc(100% - (.5rem * 2));
				}
			}
		}

		@media (min-width: 992px) {
			.modal-dialog {
				max-width: 850px;
				margin: 1.75rem auto;

				&.modal-dialog-centered {
					min-height: calc(100% - (1.75rem * 2));
				}
			}
		}
	}

	#checkout {

		#cart-progress {
			margin-bottom: 24px;

			@include media-breakpoint-up(md) {
				margin-top: 16px;
				margin-bottom: 32px;
			}

			.steps {
				display: flex;
				justify-content: space-around;
				$dimension: 1.75rem;
				$stepDelay: 0s;

				.sep {
					border-top: 1px $gray-400 solid;
					flex-grow: 1;
					position: relative;
					top: $dimension/2;

					&:after {
						content: '';
						position: absolute;
						top: -1px;
						left: 0;
						width: 0;
						height: 1px;
						border-top: 1px $success solid;

					}

					&.active {
						border-color: $success;
					}

					&.current {
						&:after {
							animation: run 1s cubic-bezier(0.4, 0.0, 0.2, 1) $stepDelay forwards;
						}
					}

					@keyframes run {
						0% {
							width: 0;
						}
						100% {
							width: 100%;
						}
					}
				}

				.step {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					font-size: 14px;

					&.active {

						.number {
							background-color: desaturate(lighten($success, 10%), 20%);
						}

						.name {
							color: $success;
						}
					}

					&.current {

						.number {
							animation: bcgColor 0s ($stepDelay + 1s) forwards;

							@keyframes bcgColor {
								100% {
									background-color: desaturate(lighten($success, 10%), 20%);
								}
							}
						}

						.name {
							animation: color 0s ($stepDelay + 1s) forwards;

							@keyframes color {
								100% {
									color: $success;
								}
							}
						}
					}

					.number {
						width: $dimension;
						height: $dimension;
						line-height: $dimension;
						border-radius: 100%;
						color: white;
						text-align: center;
						background-color: $gray-400;
						font-weight: 600;
					}

					.name {
						font-weight: lighter;
						font-style: italic;
						color: $gray-500;
						margin-top: 1rem;
						white-space: nowrap;
					}
				}
			}

		}

		#cart-table {
			background-color: white;
			padding: 2rem;
			border: 1px $card-border-color solid;

			.table {
				th {
					border: none;
                }

				td {
					font-weight: 600;
                    vertical-align: middle;

					//@include media-breakpoint-down(sm) {
					//	font-size: 16px;
					//	padding: 1rem;
					//}
				}
            }

			.productFeatures {
				font-weight: lighter;
				font-size: 16px;
				text-transform: capitalize;

				ul {
					margin-top: 8px;
					margin-bottom: 0;
				}
			}

			.productCustomization {
				font-weight: lighter;
				font-size: 16px;
			}

			.title {
				white-space: nowrap;
				max-width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}

			.description {
				font-size: 13px;
				line-height: 2em;
				white-space: normal;
			}

			.remove {
				color: $body-color;
				font-size: 20px;
				transition: color 0.3s;

				&:hover {
					opacity: 1;
				}
			}

			.rest {
				td {
					font-weight: normal;
				}
			}


			@include media-breakpoint-down(sm) {
				padding: 0;

				.image {
					width: 30%;
					//padding: 16px;
				}

				.image + * {
					width: 70%;
					//padding: 16px;
				}

				table {
					margin-bottom: 0;
					margin-top: -1px;
				}

				thead {
					display: none;
				}

				td, th {

					&:nth-child(2),
					&:nth-child(3) {
						display: none;
					}
				}

				.row {
					margin-left: -8px;
					margin-right: -8px;

					> * {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				.quantity, .price {
					//font-size: 80%;
					//font-weight: 400;
				}

				.title {
					white-space: normal;
					font-size: 14px;
				}

				.details {
					color: $text-muted;
					margin-top: 8px;

					.label {
						//font-size: 11px;
						//text-transform: uppercase;
						////font-variant: small-caps;
					}

					b {
						font-weight: 600;
					}
				}
			}

		}

		#addresses {
			//min-height: 40vh;

			.address {
				@include media-breakpoint-down(sm) {
					font-size: 14px;
				}
			}
		}

		&.summary {

			.card-body {
				.text-muted {
					color: $gray-500 !important;
				}
			}

			#checkProducts {

				td {
					padding-top: 8px;
					vertical-align: top;
				}

				.productFeatures ul {
					margin: 0;
					text-transform: capitalize;
				}
			}

			.total {

				.price {

					span {
						font-size: .5em;
					}
				}

				@include media-breakpoint-up(md) {
					position: sticky;
					top: 72px;
				}

				@include media-breakpoint-up(lg) {
					top: 32px;
				}

			}

		}

		&.shipping {

			.address {

				.badge-warning {
					background-color: lighten($warning, 15%);
					color: darken($warning, 20%);
					margin-left: 8px;
				}

				label {
                    cursor: pointer;
					width: 100%;
					font-weight: 300;

					.name {
						font-weight: 600;
					}
				}

				input:checked {

					& + label .name {
						color: $mainColor;
					}
				}
			}
		}

	}

	#categories-list {

		.category {
			margin-bottom: 30px;

			.description {
				font-size: 13px;
				line-height: 2em;
			}
		}
	}

	#addressList {

		.address {

			.card-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@include media-breakpoint-down(sm) {
					font-size: 14px;
				}
			}

			.buttons {
				margin-top: 24px;
			}

			.name {
				font-weight: 600;
			}
		}
	}

  &:not(.layout-modal) {
    .address-edit {
      background-color: white;
      border: 1px $gray-200 solid;
      padding: $grid-gutter-width/2;
    }
  }

	#addressesModal {
		.isDefault {
			color: #FFAB00;
		}
	}

	#categories-list.box {


		.category {

			position: relative;
			transition: all 0.2s ease-out;
			border-radius: 8px;

			&:hover{
				box-shadow: 8px 8px 24px rgba(0,0,0,0.25);
				transform: translateY(-15px);
				figure:after{
					background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
				}
			}


			figure{
				margin:0px;
				border-radius: 8px;
				overflow: hidden;

				&:after {
					content: '';
					background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
					display: block;
					height: calc(100% + 1px);
					width: calc(100% + 1px);
					top: 0;
					left: 0;
					position: absolute;
					border-radius: 8px;
				}

				img {
					max-width: none;
					width: 100%;
					transition: all .2s ease-in-out;
				}
			}


			figcaption {
				text-align: left;

				> div {
					position: absolute;
					bottom: 0;
					left: 0;
					padding: 2em;
					width: 100%;
					height: 43%;
				}

			}

			a, a:visited, a:hover{
				color: white;
				text-decoration: none;
			}

			h2, p {
				transform: translate3d(0, 40px, 0);
				color: white;
			}
			h2 {
				transition: transform 0.35s;
			}
			p {
				color: rgba(255, 255, 255, 0.8);
				opacity: 0;
				transition: opacity 0.2s, transform 0.35s;
			}

			&.has-description:hover {
				p {
					opacity: 1;
				}

				h2{
					transform: translate3d(0, 0, 0);
				}

				p {
					transform: translate3d(0, 0, 0);
					transition-delay: 0.05s;
					transition-duration: 0.35s;
				}

			}
		}


		/*.category > div {
			position: relative;
			border-radius: $borderRadius;
			overflow: hidden;

			.bcg {
				z-index: 0;
				width: 100%;
				//filter: grayscale(100%);
			}

			.inner {
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 0;
				top: 0;
				z-index: 1;
				text-align: center;
				padding: 40px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				color: #fff !important;

				&:after {
					content: '';
					background: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.1));
					display: block;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					position: absolute;
				}

				.description {
					margin-top: 6%;
					//font-weight: lighter;
				}

				> a {
					color: inherit;
					position: relative;
					z-index: 3;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: flex-end;

					&:hover {
						//text-decoration: none;
					}
					
					i {
						font-size: 50px;
						opacity: 0;
						transform: translate3d(0, -100%, 0);
					}
				}
				
				&:hover {
					i {
						animation: fadeInDown 0.3s;
						transform: translate3d(0, 0, 0);
						opacity: 1;
					}
				}
			}
		}*/
	}

	.pagination {
		margin: 24px 0;
		justify-content: center;
	}

}