#remindCommercialAgreementModal {
  .modal-content {
    .modal-body {
      .agreements {
        margin: 40px 0;
        .agreement {
          border-top: 1px solid #dce1e5;
          margin: 0px .5rem;
          padding: 15px 0;

          .badge {
            font-size: 16px;
            padding: .6rem;
            vertical-align: top;
            display: inline-flex;
            align-items: center;
            color: #495057;

            > img {
              height: 18px;
            }

            &.badge-danger {
              background-color: #fdd4d9;
            }

            &.badge-success {
              background-color: #79d051;
            }
          }

          &:last-child {
            border-bottom: 1px solid #dce1e5;
          }
        }
      }

      button {
        text-transform: inherit;
        margin: 0 .5rem;
        width: calc(100% - 1rem);

        & + button {
          margin-top: 10px;
        }

        @media (min-width: 576px) {
          width: calc(50% - 1rem);
          margin-top: 0 !important;
        }
      }
    }
  }
  
  @media (max-width: 767px) {
    .modal-dialog {
      max-width: calc(100% - (.5rem * 2));
      margin: .5rem auto;

      &.modal-dialog-centered {
        min-height: calc(100% - (.5rem * 2));
      }
    }
  }
  
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
      margin: 1.75rem auto;

      &.modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
      }
    }
  }
}