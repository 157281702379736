.module {
	border-radius: $borderRadius;

	.module-title {
		> * {
			font-weight: 600 !important;
			color: $gray-400;
			margin-top: 0;
		}
	}

	.mod_header_subtitle{
		padding:40px 0px;
		h3{
			color: $mainColor;
			font-weight: 600 !important;
		}
		h4{
			font-weight: 100 !important;
			color: $gray-500;
		}
	}

	&.pos_icons {
		display: inline-block;

		.dropdown {
			padding: 1rem;

          @include media-breakpoint-down(sm) {
            padding: .5rem;
          }

			> .icon {
				font-size: 1.25rem;
                text-decoration: none;
			}

          &:not(.has-megamenu) {
			.dropdown-menu {
              top: 100%;
              margin-top: 0.125rem;
              padding: 24px;
              border: none;
              border-radius: 2px;
              min-width: 250px;
              max-width: 360px;
              width: 70vw;
              transition: transform .1s cubic-bezier(0.4, 0.0, 0.2, 1), opacity .1s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
              transform: scale(0);
              transform-origin: top left;
              opacity: 0;
              display: block;

              &.dropdown-menu-end {
                right: 0;
                left: auto;
                transform-origin: top right;

                //@media (min-width: 992px) and (max-width: 1199.98px) {
                //	transform-origin: top left !important;
                //	right: auto;
                //	left: 0;
                //}
              }

              &.show {
                opacity: 1;
                transform: scale(1);
              }
            }
			}
		}
	}
}

#content {
	.module {
		padding-top: 24px;
		padding-bottom: 24px;
	}
}
