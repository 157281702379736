.com_iwp_voucher {
	#content {
		@media (min-width: 1200px) {
			.container {
				max-width: 1140px;
			}
		}
	}

	#voucher-tabs {
		margin-bottom: 80px;

		&.nav-tabs {
			.nav-link {
				color: #6c757d;
				background-color: transparent;
				border: none;
				text-transform: uppercase;
				padding: 20px 1rem;

				> i {
					margin-right: 10px;
				}

				&.active {
					color: #343a40;
					border-bottom: 2px solid #343a40;
				}
			}
		}

		@media (max-width: $screen-md) {
			margin-bottom: 40px;
		}
	}

	.tab-content {
		.tab-pane {

			&#codes-tab {
				#codes, #code {
					font-size: 24px;
					font-weight: bold;
					padding: 20px;
					background-color: #f8f9fa;

					&.has-error {
						border-color: #dc3545;
						box-shadow: 0px 0px 15px 0px rgba(#dc3545, 0.3);
						transition: all 0.3s;

						&:focus, &.is-empty {
							border-color: #ced4da;
							box-shadow: none;
						}
					}
				}

				.codes-detail {
					color: #6c757d;
					text-decoration: none !important;
				}
			}

			.video-container {
				position: relative;
				display: flex;
				background-color: #dee2e6;
				border: 1px solid rgba(#6c757d, .4);
				border-radius: $border-radius;

				> img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 150px;

					@media (max-width: 768px) {
						width: 80px;
					}
				}

				.shot {
					width: 100%;
					z-index: 1;

					> div {
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: white;
						opacity: 0;
						z-index: 2;
					}
					&.is-shooting {
						animation: brightness .35s;

						> div {
							animation: shoot .35s;
						}
					}
				}


				video {
					width: 100%;
					object-fit: cover;
					//height: auto !important;
				}
			}

			.scan-info {
				text-transform: none;
			}

			.scan-detail {
				color: #6c757d;
				text-decoration: none !important;

				> i {
					margin-right: 5px;
					color: #dee2e6;
				}
			}

			.image-container {
				position: relative;
				display: flex;
				background-color: #f8f9fa;
				border: 1px solid #d0dce2;
				border-radius: $border-radius;

				.dashed-border {
					width: calc(100% - 20px);
                    height: calc(100% - 20px);
					border: 2px #d0dce2 dashed;
					border-radius: 3px;
					margin: 10px;

					.info {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-align: center;

						> img {
							margin-bottom: 60px;
						}
					}

					.image {
						width: 100%;
						height: 100%;
						text-align: center;
						display: none;

						> img {
							height: 300px;
							object-fit: contain;
							object-position: center;
						}
					}
				}

				&.has-image {
					.info {
						display: none;
					}

					.image {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.response {
				position: absolute;
				display: none;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				z-index: 10;

				&.camera-response {
					display: flex;
					width: 100%;
					height: 100%;
					color: #856404;
					padding: 20px;
					text-align: center;
					background-color: #fff3cd;
					border: 1px solid #ccc2a6;
					border-radius: $border-radius;

					> i {
						margin-bottom: 60px;
					}
				}

				&.info-response {
					position: absolute;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					z-index: 10;
					display: flex;
					bottom: 40px;
					right: 20px;
					width: 40%;

					#toast {
						width: 100%;
						display: flex;
						align-items: center;
						color: white;
						padding: 20px 40px;
						border-radius: 50px;
						opacity: 1;
						transform: translateY(0px);
						transition: transform .3s, opacity .3s;

						&.is-success {
							background-color: #28a745;
							animation: toast-success 2s;
							animation-fill-mode: forwards;
						}

						&.is-danger {
							background-color: #dc3545;
							animation: toast-warning .3s;
						}

						&.is-hidden {
							transform: translateY(-40px);
							opacity: 0;
						}

						&+#toast {
							margin-top: 15px;
						}

						i {
							font-size: 32px;
						}

						.message {
							margin-left: 20px;
							line-height: 1.2;
						}

						.btn-ok {
							flex: 1;
							text-align: right;
						}
					}
				}
			}

			@media (max-width: $screen-md) {

				.video-container, .image-container {
					height: 60vh;
				}

				.image-container {
					.info {
						padding: 40px;

						> img {
							margin-bottom: 30px !important;
							width: 50px;
						}

						h4 {
							font-size: 18px;
						}

						#chooseFile {
							font-size: 14px;
						}
					}

					.image {
						> img {
							width: 100%;
						}
					}
				}

				.response {

					&.camera-response {
						> i {
							margin-bottom: 30px;
							font-size: 3em;
						}
					}

					&.info-response {
						left: auto;
						right: auto;
						width: 100%;
						bottom: 20px;
						padding: 0 6vw;

						#toast {
							font-size: 80%;
							padding: 10px;

							i {
								font-size: 18px !important;

							}

							.message {
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		#voucher-tabs {
			&.nav-tabs {
				.nav-link {
					font-size: 20px;

					> span {
						display: block;
						font-size: 50%;
					}

					> i {
						margin-right: 0;
					}
				}
			}
		}

	}

	#scanDetailModal, #codesDetailModal {
		-webkit-overflow-scrolling: touch;

		.modal-body {
			p {
				&+p {
					border-top: 1px solid #e9ecef;
					padding-top: 1rem;
				}

				&.success {
					> i {
						color: #28a745;
						margin-right: 10px;
					}
				}

				&.danger {
					 > i {
						 color: #dc3545;
						 margin-right: 10px;
					 }
				}
			}
		}
	}

	&.layout-approval {

		.tab-content {
			.tab-pane {

				.response {
					&.info-response {
						width: 100%;
						bottom: 0px;
						right: 0px;

						#toast {
							opacity: 1 !important;
							border-radius: 0;
							transform: translateY(100%);
							transition: transform .3s;


							&.is-success {
								background-color: #28a745;
								animation: toast-success-1 2s;
								animation-fill-mode: none;
							}

							&.is-danger {
								background-color: #ed8e00;
								animation: toast-warning-1 .3s;
								transform: translateY(0%);
							}

							&.is-hidden {
								transform: translateY(100%);
							}

							.message {
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;

								.btn:not(.btn-ok) {
									text-transform: inherit;
									background: #fff;

									&:hover {
										color: initial;
									}

									&+.btn {
										margin-left: 5px;
									}
								}

								.btn-ok {
									flex: inherit;
									border: 1px solid #fff;
									margin-left: 5px;
								}
							}
						}
					}
				}
			}
		}

		#uploadInvoiceModal {
			#uploadInvoice {
				.invoice-area {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					background-color: #f7f8f9;
					border: 2px #abbcc6 solid;
					box-shadow: inset 0px 0px 10px -5px #000;
					border-radius: 3px;
					padding: 120px 60px;
					min-height: 355px;

					&.success {
						color: #0c5460;
						background-color: #d1ecf1;
					}

					&.error {
						color: #721c24;
						background-color: #f8d7da;
					}
				}

				#notes {
					border: 2px #abbcc6 solid;
					background-color: #f7f8f9;
				}

				@keyframes toast-success-1 {

					0% {
						transform: translateY(100%);
						opacity: 1;
					}

					15%, 85% {
						transform: translateY(0px);
						opacity: 1;
					}

					100% {
						transform: translateY(100%);
						opacity: 1;
					}

				}


				@keyframes toast-warning-1 {

					0% {
						transform: translateY(100%);
						opacity: 1;

					}

					100% {
						transform: translateY(0%);
					}
				}
			}
		}

		@media (max-width: $screen-md) {
			.tab-content {
				.tab-pane {

					.response {
						&.info-response {
							padding: 0;

							#toast {
								flex-direction: column;
								padding: 20px 4vw;
								font-size: initial;

								> div {
									flex-direction: column;
									text-align: center;

								}

								i {
									font-size: 32px !important;
									margin-bottom: 10px;
								}

								.message {
									flex-direction: column;
									margin-left: 0;

									.btn {
										display: block;
										margin-top: 10px;
										margin-left: 0;
										text-align: center;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.view-history {
		.counts {
			padding: 24px 0;
			text-transform: uppercase;

			.inner {
				padding: 16px;
				text-align: center;
				font-weight: lighter;
				border-radius: 3px;
				border: 1px #e9ecef solid;
				margin-bottom: 24px;

				.count {
					font-size: 48px;
					font-weight: bold;
					color: #495057;
				}
			}
		}
	}
}


@keyframes toast-success {

	0% {
		transform: translateY(65px);
		opacity: 0;
	}

	15%, 85% {
		transform: translateY(0px);
		opacity: 1;
	}

	100% {
		transform: translateY(-40px);
		opacity: 0;
	}

}

@keyframes toast-warning {

	0% {
		transform: translateY(65px);
		opacity: 0;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes shoot {

	0% {
		opacity: 0;
	}

	50% {
		opacity: .5;
	}

	100% {
		opacity: 0;
	}

}

@keyframes brightness {

	0% {
		filter: brightness(100%);
	}

	50% {
		filter: brightness(5);
	}

	100% {
		filter: brightness(100%);
	}

}