.module_mod_iwp_brands {
    .item{
        a{
            border: 1px solid $border-color;
            border-radius: $border-radius;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            cursor: pointer;
            text-decoration: none;
            background-color: white;
            height: 120px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
}