.checkbox, .radio {
    label {
        color: $body-color;

        input {
            margin-right: .5rem;
        }
    }
}

.privacy_div {
    max-height: 200px;
    overflow: auto;
    border: 1px #eee solid;
    padding: 10px;
}
