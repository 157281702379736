.user-form {

    legend {
        margin-top: 2.5rem;
        margin-bottom: 1rem;
    }

    .form-actions {

        .validate {

            &.spin {
                padding: 6px 6px 6px 25px;
                background-image: url("../images/spin.svg");
                background-position: 5px center;
                background-repeat: no-repeat;
                background-size: 18px;
            }
        }
    }
}