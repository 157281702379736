.module_mod_iwp_welcome {
  .title {
    display:inline-block;
    margin:20px 0px;
    border-radius:25px;
    color:white;
    padding: 15px 25px;
  }

  .text {
    padding: 16px 0px 16px 0px;
    line-height: 28px;
  }
}