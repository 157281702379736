.module_mod_iwp_points {
  font-size: 90%;

  .points {
    font-weight: bolder;

    .format {
      ins {
        text-decoration: none;
        display: inline-block;
        padding-right: 1px;
        opacity: 0.3;
      }
    }
  }
}