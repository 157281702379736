.com_iwp_contacts {

	#contacts {
		
		.privacy {
			font-size: 12px;
			line-height: 2em;
			height: 100px;
			overflow-y: scroll;
			border: 1px $card-border-color solid;
			padding: 10px;
			margin: 10px 0 20px 0;
		}
	}

    &.view-ticket {

		@include media-breakpoint-up(xl) {
			&.user-guest #main-content > div {
				max-width: 60%;
				flex-basis: 60%;
				margin: auto;
			}
		}

		#content, #content .container {
			background-color: $gray-100;
		}

		.card {
			margin-bottom: 32px;
			width: 80%;
			border-radius: 20px;
			border-top-left-radius: 0px;
			box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.1);

			&.support-message {
				margin-left: auto;
				border-top-right-radius: 0px;
				border-top-left-radius: 20px;
			}

			.title {
				font-size: 12px;
				margin-top: 16px;
				color: $gray-500;
				display: flex;
				justify-content: space-between;

				.sender {
					text-transform: uppercase;
					letter-spacing: 0.1em;
					margin-right: 2em;
				}

				.date {
					font-style: italic;
				}
			}


			.attachment {
				display: inline-block;
				margin-top: 16px;
				padding-top: 8px;
				border-top: 1px $gray-200 solid;
			}

			.continueBox {
				margin-top: 24px;
				background-color: lighten($primary, 10%);
				color: white;
				padding: 16px;
				font-size: 14px;

				.btn {
					padding-left: 2em;
					padding-right: 2em;

					& +.btn {
						margin-left: 1em;
					}
				}
			}
		}


	}

	&.view-tickets {

		#content, #content .container {
			background-color: $gray-100;
		}

		.tickets {
			.ticket {
				background-color: #fff;
				padding: 25px;
				margin-bottom: 40px;
				border-radius: 8px;
				box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
			}
		}

	}

}