.com_iwp_commercialagreements {
  &.view-agreements {
    #app {
      padding: 60px 0;

      .agreement {
        margin-bottom: 20px;

        .thumbnail {
          height: 370px;
          width: 100%;
          overflow-y: hidden;
          background-color: #475058;
          position: relative;
          z-index: 99;

          > img {
            width: 75%;
            margin-top: 40px;
            z-index: 0;
          }

          .overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#475058, 0);
            transition: background-color 0.35s;

            > .btn {
              width: 33%;
              opacity: 0;
              transform: translateY(60px);
              transition: transform .35s, opacity 0.35s;

              @media (max-width: 576px) {
                width: 75%;
                margin-left: 0;
                margin-right: 0;

                & + .btn {
                  margin-top: 10px;
                }
              }
            }
          }

          &:hover {
            .overlay {
              background-color: rgba(#475058, 0.9);
              pointer-events: auto;

              > .btn {
                transform: translateY(0px);
                opacity: 1;
              }
            }
          }

          @media (max-width: 576px) {
            height: 250px;

            .overlay {
              flex-direction: column;
            }
          }
        }

        .info {
          border: 1px solid #dce1e5;
          //border-top: none;
          padding: 30px 2vw;

          small {
            color: #aab4bd;
          }

          @media (max-width: 576px) {
            text-align: center;
          }

          @media (min-width: 1200px) {
            padding: 30px;
          }
        }
      }
    }
  }



  &.view-agreement {
    .agreement {
      width: 100%;
      height: 100%;

      #step-1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;


        .heading {
          max-height: 600px;
          transition: max-height 600ms ease-in;
          transition-delay: 300ms;

          .info {
            padding: 30px 4vw;
          }

          small {
            color: #aab4bd;
          }

          @media (max-width: 576px) {

            .info {
              padding: 15px 4vw;

              small {
                font-size: 70%;
              }

              .title {
                font-size: 18px;
              }

              .badge {
                font-size: 80%;
                padding: .4rem;

                > img {
                  font-size: 80%;
                  height: 14px !important;
                }
              }
            }
          }
        }

        .left-container {
          position: relative;
          background-color: #475058;
          width: 100%;
          min-height: 250px;
          height: 100%;
          overflow: hidden;
          transition: none;
          display: flex;

          .thumbnail {
            width: 100%;
            text-align: center;
            flex-grow: 1;

            > i {
              position: absolute;
              top: 0;
              right: 0;
              color: #fff;
              background: rgba(0, 0, 0, .8);
              font-size: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              padding: 10px 0;
              cursor: pointer;
              z-index: 1;

              &:first-of-type {
                right: 48px !important;
                border-right: 1px solid #aaa;
              }
            }

            > img {
              width: 100%;
              transform: scale(0.75);
              transform-origin: top center;
              transition: transform .3s;
              transition-delay: .6s;
              padding: 40px 20px;
            }

            @media (max-width: 576px) {

              > i {
                width: 38px;
                font-size: 18px;

                &:first-of-type {
                  right: 38px !important;
                }
              }
            }

          }
        }

        .right-container {
          flex-grow: 1;
          background-color: #f7f8f9;
          z-index: 1;
          visibility: visible;
          max-height: 100%;
          transition: max-height 600ms ease-in;
          transition-delay: .2s;
          overflow-x: hidden;

          .heading {
            display: none;
            transition: none;
          }

          .content {
            position: relative;
            padding: 30px 4vw;


            #signatureTab {
              border-bottom: none;


              .nav-item {
                flex-basis: 50%;

                .nav-link {
                  border: 1px solid #dee2e6;
                  border-right-width: 0px;
                  padding: 1rem 1.2rem;
                  text-align: center;
                  background-color: #fff;
                  color: #475058;
                  border-radius: 0px;
                  text-transform: uppercase;
                  border-top-left-radius: 30px;
                  border-bottom-left-radius: 30px;
                  flex-basis: 50%;

                  &.border-radius {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                  }

                  &.active {
                    background-color: #475058;
                    color: #fff;
                  }

                  @media (max-width: 576px) {
                    padding: .5rem .6rem;
                  }
                }

                & + .nav-item {
                  .nav-link {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    border-left-width: 0px;
                    border-right-width: 1px;
                  }
                }
              }
            }

            #signatureTabContent {
              margin-top: 30px;

              #sign {
                .sign-agreement {

                  #signature-canvas {
                    border-radius: $border-radius;
                    border: 1px solid #ccc;
                    background: #fff;
                    //height: 80px;
                    margin-bottom: 8px;
                  }

                }
              }

              #upload {
                .steps {
                  margin: 0;
                  text-align: center;

                  .step {
                    position: relative;
                    z-index: 10;

                    .number {
                      width: 2.375rem;
                      height: 2.375rem;
                      line-height: 2.375rem;
                      border-radius: 100%;
                      color: #dce1e5;
                      text-align: center;
                      background-color: #f7f8f9;
                      border: 1px solid #dce1e5;
                      margin: auto;
                    }

                    .text {
                      padding: 10px 0;
                    }

                    .sep {
                      width: 1px;
                      margin-top: 0;
                      margin-bottom: 0;
                      border: 0;
                      border-top: 20px solid #dce1e5;
                    }
                  }
                }

                .drop-zone {
                  background-color: #fff;
                  border: 1px solid #dce1e5;
                  border-radius: $border-radius;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  position: relative;
                  top: -19px;
                  padding: 40px 20px;
                  margin-bottom: 29px;
                }
              }
            }
          }
        }

        &.expanded-thumb {
          overflow: hidden;

          .heading {
            max-height: 0px;
            transition: max-height .6s ease-out;
            transition-delay: 0s;
          }

          .left-container {
            overflow:auto;
            -webkit-overflow-scrolling: touch;

            .thumbnail {
              > img {
                transform: scale(1);
                transform-origin: top center;
                transition: transform .3s;
                transition-delay: .6s;
              }
            }
          }

          .right-container {
            max-height: 0;
            transition: max-height .6s ease-out;
            transition-delay: .2s;
          }
        }

        @media (min-width: 1024px) {
          flex-direction: row !important;
          height: 100%;

          .heading {
            display: none;
          }

          .left-container {
            width: 60%;
            height: 100%;
            transition: width .3s;

            .thumbnail {
              height: 100%;
              padding: 0;

              > img {
                width: auto !important;
                padding: 0;
                height: auto;
                max-height: 100%;
                object-fit: cover;
                position: relative;
                margin: 100px 0;
                transition: max-height .3s;
                transform: none;
              }
            }
          }

          .right-container {
            width: 40%;
            transition: width .3s, visibility .3s;
            visibility: visible;
            overflow-y: auto;

            .heading {
              display: block;
              height: auto;
              background-color: #fff;
              border: 1px solid #dce1e5;

              .info {
                padding: 25px;
                margin-bottom: 30px;
              }
            }

            .content {
              padding: 25px 25px 25px;

              #signatureTab {
                width: 70%;
                margin: 0 auto;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);

                .nav-item {
                  flex-basis: 50%;
                }
              }

              #signatureTabContent {
                //margin-top: 20px;
              }
            }
          }

          &.expanded-thumb {
            .left-container {
              width: 100%;

              .thumbnail {
                overflow-y: auto;

                > img {
                  max-height: 180% !important;
                }
              }
            }

            .right-container {
              width: 0;
              visibility: hidden;
              transition: width .3s;

              .heading {
                max-height: inherit;
              }
            }
          }
        }

        @include media-breakpoint-up(xxl) {
          .right-container {
            .heading {
              .info {
                padding: 30px;
                margin-bottom: 40px;
              }
            }

            .content {
              padding: 60px 30px 30px;

              #signatureTabContent {
                margin-top: 30px;
              }
            }
          }
        }
      }

      #step-2 {
        max-width: 600px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
        padding: 0 10px;


        @media (max-width: 576px) {

          i {
            font-size: 4em;
          }

          .title {
              font-size: 21px;
          }
        }
      }
    }

    #system-message {
      position: fixed;
      width: 60% !important;
      left: 50%;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;

      .alert {
        box-shadow: 0 0 25px rgba(100,100,100,0.2);
      }

      @media (max-width: 768px) {
        width: 90% !important;
      }
    }
  }
}

.com_iwp_commercialagreements, .plg_iwp_remind_commercial_agreements, .module_mod_iwp_targets,  .module_mod_objectives {
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 4em;
    font-size: 11px;
    color: #ccc;
    background-color: #ccc;
    animation: load 1s infinite ease-in-out;
    animation-delay: -0.16s;
    transform: translate(-50%, -50%);


    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      background-color: #ccc;
      width: 1em;
      height: 4em;
      animation: load 1s infinite ease-in-out;
    }

    &:before {
      left: -1.5em;
      animation-delay: -0.32s;
    }

    &:after {
      left: 1.5em;
    }
  }

   .error-thumb {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     text-align: center;
     padding: 10px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     background-color: #475058;
     color: #fff;
   }

  .agreement {
    .badge {
      font-size: 14px;
      padding: .625rem;
      border-radius: $btn-border-radius;
      vertical-align: top;
      align-items: center;
      color: $body-color;

      > img {
        width: 100%;
        height: .980rem;
      }

      &.badge-danger {
        background-color: #fdd4d9;
      }

      &.badge-success {
        background-color: #79d051;
      }
    }
  }


  #commercialAgreementModal {
    -webkit-overflow-scrolling: touch;

    .modal-dialog {
      height: calc(100% - 1rem);


      .modal-content {
        height: auto;
        min-height: 100%;
        border: none;
        border-radius: 0;

        .modal-body {
          margin: 0;
          padding: 0;
          position: absolute;
          width: 100%;
          height: 100%;

          iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-width: 0;
          }
        }
      }

      @media (min-width: 576px) {
        max-width: 90%;
        margin: calc(10vh/2) auto;
        height: 90vh;
        transform: none;
        overflow-y: hidden;

        .modal-content {
          overflow-y: auto;
        }
      }

      @include media-breakpoint-up(xxl) {
        margin: calc(20vh/2) auto;
        height: 80vh;
      }
    }
  }

  .popover {
    z-index: 1050;
    max-width: 300px;
    margin-bottom: 1em;

    .popover-body {
      padding: 1em;
      color: #80919b;
    }
  }
}



@keyframes delay-overflow {
  from {
    overflow: visible;
  }
}

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}