.dropdown {
  &.has-megamenu {
    position: static !important;
  }

  .dropdown-menu {
    &.megamenu {
      left: 0;
      right: 0;
      width: 100% !important;
      max-width: 100% !important;
      max-height: calc(100vh - #{$navbar-height});
      height: auto;
      margin-top: $dropdown-spacer !important;
      border-radius: 0;
      overflow-y: scroll;
      display: block;
      border: none;
      visibility: hidden;
      opacity: 0;
      transition: visibility .3s linear, opacity .3s linear, transform .2s ease-in-out .6s;
      box-shadow: $box-shadow-sm;
      top: 100%;
      padding: 0;

      &.show {
        visibility: visible;
        opacity: 1;
        transition: visibility .3s linear, opacity .3s linear;
      }

      @include media-breakpoint-down(md) {
        top: 0;
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        margin-top: 0 !important;
        overflow-x: hidden;
        box-shadow: none;
      }
    }
  }
}