.main-color {
	color: $mainColor;
}

.full-width {
  width: 100vw;
  max-width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50vw);
}

.form-group {

	&.icon-addon {

		.form-control {
			padding-left: 3em;
		}

		i {
			color: $gray-400;
			position: absolute;
			height: $formControlHeight;
			padding: 6px 1em;
			line-height: 34px;
		}
	}
}

.light {
	font-weight: lighter;
}

.bold {
	font-weight: bold;
}

.pointer {
	cursor: pointer;
}

.hidden {
	display: none;
}


.faster {
	animation-duration: 500ms !important;
}

.text-transform-none {
	text-transform: initial !important;
}

// modal width

.modal {
	@media (max-width: 991.98px) {
		.modal-dialog {
			max-width: calc(100% - (.5rem * 2));
			margin: .5rem auto;

			&.modal-dialog-centered {
				min-height: calc(100% - (.5rem * 2));
			}
		}
	}

	@media (min-width: 992px) {
		.modal-dialog {
			max-width: 850px;
			margin: 1.75rem auto;

			&.modal-dialog-centered {
				min-height: calc(100% - (1.75rem * 2));
			}
		}
	}

}


// Margin e padding generazione automatica
/*
$positions: (
		top: 't',
		bottom: 'b',
		left: 'l',
		right: 'r'
);

@mixin generateMpClasses($prop, $propAbbr, $unit, $from, $to, $multiplier) {
	@for $i from $from to $to {
		@each $position, $abbr in $positions {
			.#{$propAbbr}#{$abbr}-#{$i*$multiplier} {
				#{$prop}-#{$position}: #{$i*$multiplier}#{$unit} !important;
			}
		}

		.#{$propAbbr}-#{$i*$multiplier} {
			#{$prop}: #{$i*$multiplier}#{$unit} !important;
		}
	}
}

@include generateMpClasses('margin', 'm', 'px', 1, 20 , 8);
@include generateMpClasses('padding', 'p', 'px', 1, 20, 8);
@include generateMpClasses('padding', 'pw', 'vw', 1, 15, 1);
*/
// ------------------


// box-shadow material

@mixin box-shadow ($level) {

	@if $level == 1 {
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	} @else if $level == 2 {
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	} @else if $level == 3 {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	} @else if $level == 4 {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	} @else if $level == 5 {
		box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	}

}

@mixin box-shadow-gen() {
	@for $i from 1 to 5 {
		.shadow-#{$i} {
			@include box-shadow($i);
		}
	}
}

@include box-shadow-gen();

// -----------------------



@mixin text-gray($val) {
	color: $val !important;
}

@each $value, $color in $grays {
	.text-gray-#{$value} {
		@include text-gray($color);
	}
}


// Maintain Aspect Ratio Mixin

@mixin ratio($breakpoints, $width, $height) {
	@include media-breakpoint-up($breakpoints) {
		position: relative;
		&:before {
			display: block;
			content: "";
			width: 100%;
			padding-top: ($height / $width) * 100%;
		}
		> div {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left:0;
			display: flex;
		   justify-content: center;
		   align-items: center;
		}
	}
}


$breakpoints: ('','sm','md','lg','xl');
$positions: ('':'', 'top': 't', 'bottom': 'b', 'left': 'l', 'right': 'r');

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @for $i from 1 through 20 {
        $step: 8;
        $size: $i * $step;
        $length: $i * $step + px;

        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}e#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}s#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }
  }
}

@mixin text-align($breakpoints, $align) {
	@include media-breakpoint-up($breakpoints) {
	text-align: $align;
	}
}


@each $breakpoint in $breakpoints {
	.ratio-#{$breakpoint}-16-9 {
		@include ratio($breakpoint, 16, 9);
	}
	.ratio-#{$breakpoint}-4-3 {
		@include ratio($breakpoint, 4, 3);
	}
	.ratio-#{$breakpoint}-1-3 {
		@include ratio($breakpoint, 1, 3);
	}
	.ratio-#{$breakpoint}-2-1 {
		@include ratio($breakpoint, 2, 1);
	}
	.ratio-#{$breakpoint}-1-2 {
		@include ratio($breakpoint, 1, 2);
	}
}


@each $breakpoint in $breakpoints {
	@for $i from 0 through 20 {
		$step: 8;
		@each $position, $abbr in $positions {
			$value: $i * $step;
			$b: if($breakpoint == '', '', -#{$breakpoint});

			@include media-breakpoint-up($breakpoint) {

				.p#{$abbr}#{$b}-#{$value} {
					@if $position != "" {
						padding-#{$position}: #{$value}px !important;
					} @else {
						padding: #{$value}px !important;
					}
				}

				.m#{$abbr}#{$b}-#{$value} {
					@if $position != "" {
						margin-#{$position}: #{$value}px !important;
					} @else {
						margin: #{$value}px !important;
					}
				}

			}


		}
	}
}

.fa-spin-1 {
	-webkit-animation: fa-spin-1 2s linear infinite;
	animation: fa-spin-1 2s linear infinite;
}

@-webkit-keyframes fa-spin-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg) !important;
		transform: rotate(359deg) !important;
	}
}
@keyframes fa-spin-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg) !important;
		transform: rotate(359deg) !important;
	}
}


@keyframes scroll-text {
	0% {
		transform: translateX(0%);
	}
	90% {
		transform: translateX(-100%);
	}
	95% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(0%);
	}
}



