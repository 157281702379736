.module_mod_iwp_targets {

  .intro {
    margin-bottom: 24px;
  }

  #mod_iwp_targets {
    .agreement {
      border: 1px $gray-500 solid;
      border-radius: $border-radius;


      .accordo-title {
        border-bottom: 1px $gray-500 solid;
        padding: 32px 24px;

        .text-muted {
          color: $gray-500 !important;
          letter-spacing: .1em;
        }

        .crediting-date {

          @include media-breakpoint-up(lg) {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #eee;
          }
        }
      }

      .targets {

        .target {

          .formula {
            text-transform: uppercase;
          }

          .progress {
            height: 36px;
            border-radius: 36px;
            font-size: 1rem;
            &.active {
              .progress-bar {
                transition: none !important;
              }
            }
            .progress-bar {
              border-radius: 36px;
              background: linear-gradient(left, darken($primary, 5%) 0%, lighten($primary, 5%) 100%);
              font-weight: bold !important;
            }
          }


          &.parent {
            .sons {
              padding: 40px 0 80px;
              border: 1px solid $gray-500;
              border-radius: 20px;
              overflow: hidden;
              margin: 0;

              > .progress {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                border-radius: 0;
                padding: 0;

                .progress-bar {
                  border-radius: 0 20px 20px 0;
                }
              }

              .son {
                &.completed {
                  .formula {
                    color: $primary !important;
                  }
                }
              }
            }
          }

          &.completed {
            > div:first-child {
              .formula {
                color: $primary !important;
              }
            }

            .sons {
              > .progress {
                .progress-bar {
                  border-radius: 0;
                }
              }
            }
          }

          @include media-breakpoint-down(sm) {

            .h5 {
              font-size: 1rem;

              .text-scroll {
                width: 100%;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                margin-right: 5px;

                &:not(:hover) {
                  text-overflow: ellipsis;
                }

                .name {
                  display: initial;
                }

                &:hover, &:focus {
                  .name {
                    display: inline-block;
                    animation: scroll-text 5s ease 0s 2 normal;

                  }
                }
              }
            }

            .progress {
              font-size: .75rem;
            }
          }
        }

        &.grid {
          @include media-breakpoint-down(md){
            .target, .son {
              & + .target, & ~ .son {
                margin-top: 25px;
              }
            }
          }

          .target {
            & + .parent {
              margin-top: 40px;
            }

            & + .w-100:not(:last-of-type) {
              margin-top: 40px;
            }
          }

          .parent {
            & + .target {
              margin-top: 40px;
            }

            .sons {
              .w-100 {
                & ~ .son {
                  margin-top: 40px;

                  @include media-breakpoint-down(md){
                    margin-top: 25px;
                  }
                }
              }
            }

            @include media-breakpoint-down(md){
              & + .target {
                margin-top: 25px;
              }
            }
          }
        }

        &.list {
          .target, .son {
            & + .target, & ~ .son {
              margin-top: 40px;

              @include media-breakpoint-down(md){
                margin-top: 25px;
              }
            }
          }
        }
      }


      .modal.detail {
        table {
          th {
            border-top: none;
          }
        }
      }

      &+ .agreement {
        margin-top: 40px;
      }
    }
  }

  #winModal {
    .agreement {
      border: 1px solid #e9ecef;
      border-radius: $border-radius;
      overflow: hidden;

      .accordo-title {
        padding: 15px;
        border-bottom: 1px solid #e9ecef;
      }

      .wrapper {
        background: #f4f4f4;
        .targets {

          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;

          .target {
            display: flex;
            flex: 0 0 35%;
            margin: 10px;
            border-radius: 50px;
            background-color: $primary;
            color: white;

            .name {
              flex: 1 1 100%;
              border-right: 1px solid white;
              padding: 10px 15px;
            }

            .formula {
              display: flex;
              align-items: center;
              padding: 10px 15px;
            }

            &.son-crediting-info {
              background-color: $secondary;
            }
          }


          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;

          }
        }

        .info {
          padding: 10px;
          background-color: $secondary;
          color: white;
          text-align: center;
          font-size: small;
          font-weight: lighter;
        }
      }

      & + .agreement {
        margin-top: 20px;
      }
    }

    .points {
      position: relative;
      padding: 20px;
      margin-top: 36px;
      background-color: #f4f4f4;
      border: 1px solid #e9ecef;
      border-radius: 3px;

      > i {
        position: absolute;
        top: -50%;
        transform: translateY(50%);
      }

      span {
        color: $primary;
        font-weight: bold;
      }
    }
  }
}