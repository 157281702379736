#orderStatus {

  .counts {
    margin-bottom: 24px;
    margin-top: 24px;
    text-transform: uppercase;

    .inner {
      padding: 16px;
      text-align: center;
      font-weight: lighter;
      border-radius: $border-radius;
      border: 1px $gray-200 solid;
      margin-bottom: 24px;

      .count {
        font-size: 48px;
        font-weight: bold;
        color: $body-color;
      }
    }
  }


  table {

    td, th {
      white-space: nowrap;
    }

    .info {
      padding: 0;
      border: none;

      > div {
        padding: 16px;
      }
    }

    .modal {

      .cart-data {
        margin-bottom: 32px;

        > div {
          margin-bottom: 16px;
        }

        .quantity {
          margin-right: 8px;

          span {
            margin-left: 8px;
          }
        }

        ul {
          margin: 0;
        }
      }


      .client-data {
        line-height: 4ex;

        i {
          color: $gray-500;
          margin-right: 16px;
        }
      }

    }
  }

  #accordionOrders {
    .accordion-item {
      @include media-breakpoint-down(sm) {
        .productTotal {
          font-size: $font-size-base;
        }
      }
    }
  }

}