// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

/* VARIABILI COLORI */
$primary:       #d52230;
$secondary:     $gray-800;
$dark:          $gray-900;


$mainColor: $primary;
$textColor: $dark;

$body-color: $gray-900;

$font-weight-thin: 100;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;
$font-weight-bolder: bolder;
$font-weight-black: 900;

$sizes: (
        5: 5%,
        10: 10%,
        15: 15%,
        20: 20%,
        25: 25%,
        30: 30%,
        35: 35%,
        40: 40%,
        45: 45%,
        50: 50%,
        55: 55%,
        60: 60%,
        65: 65%,
        70: 70%,
        75: 75%,
        80: 80%,
        85: 85%,
        90: 90%,
        95: 95%,
        100: 100%
);

$utilities: ();
// Utilities
$utilities: map-merge(
                (
                        "font-weight": (
                                property: font-weight,
                                class: fw,
                                values: (
                                        thin: $font-weight-thin,
                                        light: $font-weight-light,
                                        lighter: $font-weight-lighter,
                                        normal: $font-weight-normal,
                                        medium: $font-weight-medium,
                                        semi-bold: $font-weight-semi-bold,
                                        bold: $font-weight-bold,
                                        bolder: $font-weight-bolder,
                                        black: $font-weight-black,
                                )
                        ),
                        "flex-basis": (
                                responsive: true,
                                property: flex-basis,
                                class: flex,
                                values: (
                                        basis-0: 0%,
                                        basis-5: 5%,
                                        basis-10: 10%,
                                        basis-15: 15%,
                                        basis-20: 20%,
                                        basis-25: 25%,
                                        basis-30: 30%,
                                        basis-35: 35%,
                                        basis-40: 40%,
                                        basis-45: 45%,
                                        basis-50: 50%,
                                        basis-55: 55%,
                                        basis-60: 60%,
                                        basis-65: 65%,
                                        basis-70: 70%,
                                        basis-75: 75%,
                                        basis-80: 80%,
                                        basis-85: 85%,
                                        basis-90: 90%,
                                        basis-95: 95%,
                                        basis-100: 100%,
                                        basis-auto: auto
                                )
                        ),
                        "viewport-height": (
                                responsive: true,
                                property: height,
                                class: vh,
                                values: (
                                        5: 5vh,
                                        10: 10vh,
                                        15: 15vh,
                                        20: 20vh,
                                        25: 25vh,
                                        30: 30vh,
                                        35: 35vh,
                                        40: 40vh,
                                        45: 45vh,
                                        50: 50vh,
                                        55: 55vh,
                                        60: 60vh,
                                        65: 65vh,
                                        70: 70vh,
                                        75: 75vh,
                                        80: 80vh,
                                        85: 85vh,
                                        90: 90vh,
                                        95: 95vh,
                                        100: 100vh
                                )
                        ),
                        "min-viewport-height": (
                                responsive: true,
                                property: min-height,
                                class: min-vh,
                                values: (
                                        5: 5vh,
                                        10: 10vh,
                                        15: 15vh,
                                        20: 20vh,
                                        25: 25vh,
                                        30: 30vh,
                                        35: 35vh,
                                        40: 40vh,
                                        45: 45vh,
                                        50: 50vh,
                                        55: 55vh,
                                        60: 60vh,
                                        65: 65vh,
                                        70: 70vh,
                                        75: 75vh,
                                        80: 80vh,
                                        85: 85vh,
                                        90: 90vh,
                                        95: 95vh,
                                        100: 100vh
                                )
                        ),
                ),
                $utilities
);

// Links
$link-color: $secondary;


// Typography
$small-font-size: .875em;


// Brand Logo
$navbar-brand-margin-end: 1rem;

$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0;
$navbar-height: 105px;

$navbar-nav-link-padding-x: 1rem;

$navbar-light-color: $body-color;

/* Breadcrumbs */
$breadcrumb-active-color: $primary;

$mobileMenuTextColor: white;

$input-placeholder-color: $body-color;

$btn-border-radius-sm: 30px;
$btn-border-radius: 40px;
$btn-border-radius-lg: 50px;

// box-model
$borderRadius: 0px;

// screen sizes
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

// forms
$formControlHeight: 46px;

// font
$font-family-sans-serif:  var(--font-family-body, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
/*$fontFamily: 'Montserrat', sans-serif;*/

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);

// transition
$transitionEasing: cubic-bezier(0.4, 0.0, 0.2, 1);


// BOOTSTRAP
//$headings-color: $textColor;
//$body-color: #475058;
$border-color: #e9ecef;
$card-border-color: $border-color;
//$grid-gutter-width: 48px;

$border-radius: 3px;

// badges
$badge-lg-font-size: 14px;
$badge-lg-font-weight: 400;
$badge-lg-padding-y: .5em !default;
$badge-lg-padding-x: .8em !default;

$badge-pill-lg-padding-x: 1.2em !default;

// Dropdown
$dropdown-box-shadow: $box-shadow-sm;

// container
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1340px
) !default;

$spacer: 1rem;
$navbar-padding-y: $spacer * 1.2;

// Popovers
$popover-max-width: 400px;