.btn {
  &.unstyled {
		background: none;
		padding: 0;
		box-shadow: none;
		border: none;
	}

	&.btn-padding {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&.btn-border {
		border-width: 2px;
		background: none;
	}

	&.btn-rounded {
		border-radius: 30px;
		padding-top: 1em;
		padding-bottom: 1em;

		@media (max-width: 576px) {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}
	}

	&.btn-link,
	&.dropdown-toggle {
		text-decoration: none;
		text-transform: unset;
		letter-spacing: initial;
	}

	&.btn-link2 {
		text-decoration: none;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: .1em;
	}
}