.module {
  &.module_mod_articles_category {
    .owl-carousel {
      .owl-dots {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
}