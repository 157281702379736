#copyright {
    font-size: $small-font-size;
	background-color: $secondary;

    &.fixed {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;

		.container {
			background: none;
		}
	}
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 4em;
  font-size: 11px;
  color: #ccc;
  background-color: #ccc;
  animation: load 1s infinite ease-in-out;
  animation-delay: -0.16s;
  transform: translate(-50%, -50%);


  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    background-color: #ccc;
    width: 1em;
    height: 4em;
    animation: load 1s infinite ease-in-out;
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
  }
}

body {
   &:not(.layout-modal) {
       #system-message-container {
         position: fixed;
         top: 80px;
         z-index: 1040;
         max-width: 960px;
         width: 100%;
         padding: 20px;
         left: 0;
         right: 0;
         margin-left: auto;
         margin-right: auto;
       }
   }
}

//@mixin fixed-system-message {
//	#system-message {
//
//		position: fixed;
//		bottom: 50px;
//		z-index: 9999999999;
//		width: 50%;
//		left: 0;
//		right: 0;
//		margin-left: auto;
//		margin-right: auto;
//
//		.alert {
//			margin: 0;
//			box-shadow: 0 0 25px rgba(100,100,100,0.2);
//
//			&+.alert {
//				margin-top: 20px;
//			}
//		}
//
//		@media (max-width: $screen-md) {
//			width: 80%;
//		}
//	}
//}

//#system-message-container {
//
//	&.fixed {
//		@include fixed-system-message;
//	}
//}
//
//
//	body:not(.com_iwp_voucher) {
//		#system-message-container {
//			@include fixed-system-message;
//		}
//	}
//
//	body.com_iwp_voucher.user-guest {
//		#system-message-container {
//			@include fixed-system-message;
//		}
//	}
